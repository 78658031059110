.mapContainer {
  display: block;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

.hidden {
  display: none;
}

.tooltip {
  z-index: 99999999;
  position: absolute;
  left: 100px;
}

.link {
  z-index: 999999;
}

.leaflet-popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img-overlay {
  filter: invert(59%) sepia(69%) saturate(1644%) hue-rotate(2deg)
    brightness(107%) contrast(103%);
}

.img-overlay-wrong {
  filter: invert(10%) sepia(95%) saturate(7496%) hue-rotate(11deg)
    brightness(97%) contrast(120%);
}

.img-overlay-correct {
  filter: invert(69%) sepia(61%) saturate(4725%) hue-rotate(78deg)
    brightness(112%) contrast(128%);
}

.leaflet-control-zoom {
  top: 50px;
}

.placeholderPin {
  filter: invert(73%) sepia(81%) saturate(407%) hue-rotate(133deg)
    brightness(91%) contrast(97%);
}
